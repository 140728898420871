import CardList from '@components/CardList'
import Divider from '@components/Divider'
import { Hero, Layout, Main, Stack } from '@layout'
import BannerHorizontal from '@widgets/BannerHorizontal'
import BannerVertical from '@widgets/BannerVertical'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import Seo from '@widgets/Seo'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import { Iframe } from '../../flow-ui-layout/Iframe/Iframe'
import Banner from '../../flow-ui-widgets/Banner/Banner'

const Posts = ({
  data: { posts = {}, featuredPosts = {}, adsPosts = {}, recentPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props
  const sliderRef = React.useRef()

  return (
    <Layout {...props}>
      <Seo title='Trang thông tin mới nhất về năng lượng' />
      <Hero
        // pt={4}
        pb={5}
        sx={{
          background: t =>
            `linear-gradient(
              0deg,
              ${t.colors.omegaLighter},
              ${t.colors.background}
            )`,
          borderRadius: 20
        }}
      >
        <Divider space={2} />
        <Box sx={{ position: `relative`, zIndex: 3 }}>
          <Flex sx={{ alignItems: 'center' }}>
            <Box sx={{ display: ['none', null, 'block'], flexBasis: [`full`, null, `2/3`] }}>
              <CardList
                nodes={featuredPosts.nodes}
                variant={['vertical-feature']}
                limit={5}
                omitInteractive
                omitCategory
                // omitMedia
                omitFooter
                slider
                // autoPlay
                autoplaySpeed={5000}
                fade
                dots={false}
                arrows={false}
                // controlPosition='bottom'
                ref={sliderRef}
                loading='eager'
              />
            </Box>
            <Box sx={{ display: ['block', null, 'none'], flexBasis: [`full`, null, `2/3`] }}>
              <CardList
                nodes={featuredPosts.nodes}
                variant={['horizontal-hero']}
                limit={5}
                // omitInteractive
                omitFooter
                slider
                // autoPlay
                autoplaySpeed={5000}
                fade
                arrows={false}
                controlPosition='bottom'
                // ref={sliderRef}
                loading='eager'
              />
            </Box>
            <Box sx={{ flexBasis: [`auto`, null, `1/3`], display: ['none', null, 'block'], paddingLeft: 50 }}>
              {/* <Banner slot="4810301642" format='vertical' /> */}
              <Iframe />
              {/* <BannerVertical /> */}
            </Box>
          </Flex>
          {/* <Stack> */}
          <Box sx={{ display: [`none`, null, `block`] }}>
            <Divider />
            <CardList
              nodes={featuredPosts.nodes}
              variant={['horizontal-md', 'horizontal-aside']}
              limit={5}
              columns={[1, 0, 3]}
              omitCategory
              asNavFor={sliderRef}
              loading='eager'
            />
          </Box>
          {/* <BannerVertical /> */}

          {/* </Stack> */}
        </Box>
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <CardList
            nodes={adsPosts.nodes}
            variant={['horizontal-aside']}
            limit={3}
            // skip={3}
            columns={[1, 2, 3]}
            // omitMedia
            // omitFooter
            omitCategory
            slider
            autoPlay
            autoplaySpeed={6000}
            // smoothAutoScroll
            // fade
            arrows={false}
            // ref={sliderRef}
            loading='lazy'
            title="Bài Viết Nổi Bật"
          />
          <Divider />
          <Banner slot="2758853374" format='rectangle' />
          {/* <BannerHorizontal /> */}
          <Divider />
        </Main>
      </Stack>

      <Stack
        effectProps={{ effect: false }}
        title='Bài Viết Mới Nhất'
        direction={[`column`, null, null, `row`]}
      >
        <Main
          sx={{
            display: [`block`, null, null, `flex`]
          }}
        >
          <CardList
            nodes={recentPosts.nodes}
            variant={['vertical-cover']}
            limit={2}
          />
        </Main>
        <Divider space={2} />
        <Main
          sx={{
            mx: [0, null, null, 3],
            display: [`block`, null, null, `flex`]
          }}
        >
          <CardList
            nodes={recentPosts.nodes}
            variant={['horizontal-md', 'horizontal', 'horizontal', 'vertical']}
            limit={1}
            skip={2}
          />
        </Main>
        <Divider space={2} />
        <Main>
          <CardList
            nodes={recentPosts.nodes}
            variant={[
              'horizontal-md',
              'horizontal-md',
              'horizontal',
              'horizontal-aside'
            ]}
            limit={3}
            skip={3}
            columns={[1, 2, 1, 1]}
          />
        </Main>
      </Stack>
      {/* <Divider />
      <Hero wide>
        <CardList
          nodes={featuredPosts.nodes}
          variant='horizontal-cover-wide'
          limit={4}
          skip={6}
          slider
          autoPlay
          fade
          dots={false}
          controlPosition='bottom'
        />
      </Hero> */}
      <Divider />
      {posts.group.length &&
        posts.group.map((group, index) => (
          <React.Fragment key={`${group.categoryName}.list`}>
            {index % 2 === 0 ? (
              <Stack
                title={group.categoryName}
                titleLink={group.nodes[0].category.slug}
              >
                <Main>
                  <CardList
                    nodes={group.nodes}
                    variant={['horizontal-md', 'vertical']}
                    limit={6}
                    columns={[1, 2, 3, 3]}
                    omitCategory
                    loading='lazy'
                  />
                  <Divider />
                  {/* <BannerHorizontal /> */}
                  <Banner slot="3305648288" format='rectangle' />
                </Main>
              </Stack>
            ) : (
              <Stack
                title={group.categoryName}
                titleLink={group.nodes[0].category.slug}
              >
                <Main>
                  <CardList
                    nodes={group.nodes}
                    variant={['horizontal-md', 'horizontal']}
                    limit={6}
                    columns={[1, 1, 2]}
                    omitCategory
                    loading='lazy'
                  />
                </Main>
              </Stack>
            )}
            {index !== posts.group.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      <Divider space={1} />
      <Stack>
        <Main>{services.mailchimp && <NewsletterExpanded />}</Main>
      </Stack>
    </Layout>
  )
}

export default Posts
