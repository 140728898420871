import React, { useEffect } from 'react'
import { Box, Link } from 'theme-ui'

const ads =
  [
    // 'https://static.eclick.vn/html5/vs_002/ads/s/sungroup/2020/07/21/63904/2000x1200/dfp/pc/std/fixed/index.html',
    // 'https://static.eclick.vn/html5/vs_002/ads/s/sunmarina/2021/07/19/43899/1920x270/dfp/pc/rmd/fixed/index.html',
    // 'https://misc.dantri.com.vn/2021/08/04/13ee7f12/02081210/index.html',
    'https://static.eclick.vn/html5/vs_001/ads/h/hyundai/dongbo/2021/06/15/60452/300x600/dfp/pc/std/fixed/index.html',
    'https://static.eclick.vn/html5/vs_001/ads/e/ecooking/2021/08/09/64640/300x500/dfp/pc/std/fixed/index.html',
    'https://static.eclick.vn/html5/vs_001/ads/h/hyundai/2021/08/06/65626/300x600/dfp/pc/std/fixed/index.html',
  ]

export const Iframe = ({ children, ...props }) => { 
  const changeIframeSrc = () => {
    let iFrame = document.getElementById('myFrame');
    iFrame.src = ads[Math.trunc(Math.random() * ads.length)];
  }
  const changeIframe = () => {
    let iFrame = document.getElementById('myFrame');
    iFrame.src = iFrame.src;
  }
  useEffect(
    () => {
      changeIframeSrc();
      let intervalId = setInterval(changeIframe,10000);
      return () => {
        clearInterval(intervalId)
      }
    },[]
  )
  return (
  <Link
    variant='mute'
    target='_blank'
    title='Wattdaily'
    href='https://wattdaily.vn'
    rel='noopener'
    sx={{position: '-webkit-sticky',
    position: 'sticky',
    top: '0px',
    zIndex: -1,
    display: ['none', 'block']}}
  >
    <Box>
      {/* <div
        className="bg-video-dfp"
        id="bg-video-dfp"
        style={{ width: "100%", height: "100vh"}}        
      >
        <img src style={{ display: "none" }} />
        <iframe
          name="myFrame"
          id="myFrame"
          src="https://misc.dantri.com.vn/2021/08/04/13ee7f12/02081210/index.html"
          frameBorder={0}
          scrolling="no"
          marginWidth={0}
          marginHeight={0}
          vspace={0}
          hspace={0}
          // style={{
          //   width: "100vw",
          //   height: "calc(100vh + 217px)",
          //   minHeight: 320,
          //   position: "absolute",
          //   left: "50%",
          //   transform: "translateX(-50%)",
          //   zIndex: 0,
          // }}
          style={{
            position: "absolute",
            top: 0,
            width: 1920,
            height: 1200,
            left: "calc((100vw - 1920px) / 2)",
            zIndex: -1
          }}
        />
      </div>    */}

      <div
        id="genecy_ifr_138359077033_300x500_1628619722316"
        style={{
          display: "block",
          margin: 0,
          padding: 0,
          borderRadius: 0,
          border: 0,
          minWidth: 0,
          minHeight: 0,
          backgroundImage: "none",
          backgroundColor: "transparent",
          width: `100%`,
          height: 600,
          maxWidth: 300,
          maxHeight: 600,
          position: "relative"
        }}
      >
        <iframe
          name="myFrame"
          id="myFrame"
          title="myFrame"
          // src="https://static.eclick.vn/html5/vs_001/ads/e/ecooking/2021/08/09/64640/300x500/dfp/pc/std/fixed/index.html"
          frameBorder={0}
          scrolling="no"
          style={{
            display: "block",
            margin: 0,
            padding: 0,
            borderRadius: 0,
            border: 0,
            minWidth: 0,
            minHeight: 0,
            backgroundImage: "none",
            backgroundColor: "transparent",
            width: `100%`,
            height: 600,
            maxWidth: 300,
            maxHeight: 600
          }}
        />
      </div>
    </Box>
  </Link>
  )
}